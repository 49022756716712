export function getDataUriFromBase64(base64) {
    // Helper function to identify the format from the base64 string
    function getFormat(base64) {
        if (base64.startsWith('/9j/')) {
            return 'jpeg';
        } else if (base64.startsWith('iVBORw0KGgo')) {
            return 'png';
        } else if (base64.startsWith('R0lGODlh') || base64.startsWith('R0lGODdh')) {
            return 'gif';
        } else if (base64.startsWith('Qk')) {
            return 'bmp';
        } else if (base64.startsWith('UklGR')) {
            return 'webp';
        } else {
            throw new Error('Unsupported image format.');
        }
    }

    if (!base64) {
        return null;
    }

    // Determine the format
    const format = getFormat(base64);

    // Construct the Data URI
    const dataUri = `data:image/${format};base64,${base64}`;
    return dataUri;
}
