import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import Session from 'react-session-api'
import {makeStyles} from "@mui/styles";
import {DataGrid} from '@mui/x-data-grid';
import axios from "axios";
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import {Close, Check} from "@mui/icons-material";
import {API_ENDPOINT, COLOR_BRAND} from "../consts";


const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1
    },
    innerContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    box: {
        width: "100%",
        maxWidth: 1200,
        height: 600,
        padding: 20
    },
    filterString: {
        width: 300,
    },
    datagrid: {
        marginTop: 40
    },
    linkColor: {
        color: "#00ff00"
    }
}));

export function Customers(props) {
    const [rowsInit, setRowsInit] = useState([]);
    const [rows, setRows] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = async () => {
        axios.get(API_ENDPOINT + '?func=get_customers')
            .then(res => {
                if (res.status === 200) {
                    setRowsInit(res.data);
                    setRows(res.data);
                }
            });
    };

    const filterRows = async (string) => {
        var foundRows = [];
        if (string.length == 0) {
            getCustomers();
        } else {
            for (var i = 0; i < rowsInit.length; i++) {
                if (rowsInit[i].name.toLowerCase().indexOf(string.toLowerCase()) !== -1 || rowsInit[i].email.toLowerCase().indexOf(string.toLowerCase()) !== -1) {
                    foundRows[foundRows.length] = rowsInit[i];
                }
            }
        }
        setRows(foundRows);
    };

    const columns = [
        {
            field: 'image',
            headerName: 'Image',
            width: 75,
            editable: false,
            sortable: false,
            renderCell: (cellValues) => {
                return <Avatar src={cellValues.row.image}/>;
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 230,
            editable: false,
            sortable: true,
        },
        {
            field: 'is_admin',
            headerName: 'is Admin',
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.is_admin === 1 ? <Check color={"success"}/> : < Close color={"error"}/>}
                    </>
                );
            }
        },
        {
            field: 'is_active',
            headerName: 'Community',
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.is_active === 1 ? <Check color={"success"}/> : < Close color={"error"}/>}
                    </>
                );
            }
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            editable: false,
            sortable: false,
            minWidth: 200,
            flex: 1
        },
        {
            field: '',
            headerName: '',
            width: 75,
            editable: false,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <>
                        <NavLink to="/customer_profile" style={{color: COLOR_BRAND}} state={{id: cellValues.row.id}}>Edit</NavLink>
                    </>
                );
            }
        },
    ];

    return (
        <div className={classes.container}>
            <h1>Customers</h1>
            <div className={classes.innerContainer}>
                <div className={classes.box}>
                    <TextField
                        id="standard-basic"
                        label="Search"
                        variant="standard"
                        className={classes.filterString}
                        onChange={value => {
                            filterRows(value.target.value);
                        }}
                    />
                    <DataGrid
                        className={classes.datagrid}
                        rows={rows}
                        columns={columns}
                        pageSize={15}
                        rowsPerPageOptions={[5]}
                        disableColumnMenu
                        autoHeight
                        hideFooter

                        components={{
                            NoRowsOverlay: () => (
                                <div className={classes.noResuls}>

                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        </div>
    );

}
