import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {useSelector} from 'react-redux';
import {makeStyles} from "@mui/styles";
import {DataGrid} from '@mui/x-data-grid';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import {Flag, Edit} from "@mui/icons-material";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Cancel} from "@mui/icons-material";
import {API_ENDPOINT, COLOR_BRAND} from "../../../consts";
import Status from "./Status";

const useStyles = makeStyles((theme) => ({
    container_outer: {
        display: "flex",
        width: "100%",
        backgroundColor: "#fafafa",
        color: "#000",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        flexDirection: "column"
    },
    container_outer_save_button: {
        display: "flex",
        width: "96%",
        padding: "2%",
        justifyContent: "center"
    },
    container_inner: {
        display: "flex",
        width: "96%",
        padding: "2%",
        paddingBottom: "7%",
        color: "#000",
        flexDirection: "row"
    },
    column_left: {
        flex: 1
    },
    column_center: {
        display: "flex",
        flex: 4,
        flexDirection: "row"
    },
    column_center_half: {
        flex: 1
    },
    column_right: {
        flex: 1,
        flexDirection: "column",
        display: "flex"
    },
    input_string: {
        width: "80%",
        maxWidth: 750,
        marginBottom: 20,
        backgroundColor: "#ffffff"
    },
    horizontalSpacerSmall: {
        width: "100%",
        height: 20
    },
}));


export default function (props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState(props.data);
    const [productTypes, setProductTypes] = useState(props.data.productTypes);
    const [productTypesUsed, setProductTypesUsed] = useState([]);
    const [keyProductTypes, setKeyProductTypes] = useState(Date.now());
    const [idProductType, setIdProductType] = useState("");
    const [productTypeLabel, setProductTypeLabel] = useState("");
    const [targetGroups, setTargetGroups] = useState(props.data.targetGroups);
    const [targetGroupsUsed, setTargetGroupsUsed] = useState([]);
    const [keyTargetGroups, setKeyTargetGroups] = useState(Date.now() + 1);
    const [idTargetGroup, setIdTargetGroup] = useState("");
    const [targetGroupLabel, setTargetGroupLabel] = useState("");
    const [producers, setProducers] = useState(props.data.producers);
    const [idProducer, setIdProducer] = useState(props.data.id_producer);
    const [producerLabel, setProducerLabel] = useState(props.data.producerLabel);
    const [brands, setBrands] = useState(props.data.brands);
    const [idBrand, setIdBrand] = useState(props.data.id_brand);
    const [brandLabel, setBrandLabel] = useState(props.data.brandLabel);
    const [countryOfOrigin, setCountryOfOrigin] = useState(props.data.countryOfOrigin);
    const [idCountry, setIdCountry] = useState(props.data.id_country);
    const [countryLabel, setCountryLabel] = useState(props.data.countryLabel);

    useEffect(() => {
        filterProductTypes(props.data);
        filterTargetGroups(props.data);
    }, []);

    const filterProductTypes = (data) => {
        let productTypesUnused = [];
        let productTypesUsed = [];
        for (let i = 0; i < data.productTypes.length; i++) {
            if (data.productTypes[i].isSaved === 1) {
                productTypesUsed[productTypesUsed.length] = data.productTypes[i];
            } else {
                productTypesUnused[productTypesUnused.length] = data.productTypes[i];
            }
        }
        setProductTypes(productTypesUnused);
        setProductTypesUsed(productTypesUsed);
    };

    const filterTargetGroups = (data) => {
        let targetGroupsUnused = [];
        let targetGroupsUsed = [];
        for (let i = 0; i < data.targetGroups.length; i++) {
            if (data.targetGroups[i].isSaved === 1) {
                targetGroupsUsed[targetGroupsUsed.length] = data.targetGroups[i];
            } else {
                targetGroupsUnused[targetGroupsUnused.length] = data.targetGroups[i];
            }
        }
        setTargetGroups(targetGroupsUnused);
        setTargetGroupsUsed(targetGroupsUsed);
    };

    const saveForm = () => {
        setIsSaving(true);
        setIsReadOnly(true);
        axios.post(API_ENDPOINT + "?func=update_product", {
            id_updatedBy: user.id,
            id: props.data.id,
            section: props.data.section,
            productTypes: JSON.stringify(productTypesUsed),
            targetGroups: JSON.stringify(targetGroupsUsed),
            id_producer: idProducer == "" ? null : idProducer,
            id_brand: idBrand == "" ? null : idBrand,
            id_country: idCountry == "" ? null : idCountry
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    var tmpData = data;
                    tmpData.details_status = 1;
                    setData(tmpData);
                    setSnackbarMessage("Product saved successfully");
                    setIsSnackbar(true);
                } else {
                    setIsReadOnly(false);
                }
                setIsSaving(false);
            });
    };

    const cancelForm = () => {
        //setIsReadOnly(true);
        //filterProductTypes(props.data);
        //filterTargetGroups(props.data);
    };

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    return (
        <>
            <div className={classes.container_outer}>
                <div className={classes.container_inner}>
                    <div className={classes.column_left}>
                        <b>{props.label}</b>
                    </div>
                    <div className={classes.column_center}>
                        <div className={classes.column_center_half}>
                            <Autocomplete
                                key={keyProductTypes}
                                disablePortal
                                id="combo-box-demo"
                                className={classes.input_string}
                                style={{marginBottom: 5}}
                                options={productTypes}
                                readOnly={isReadOnly ? true : false}
                                renderInput={(params) => <TextField {...params} label="Product Types" style={{backgroundColor: isReadOnly ? "#00000012" : "#fff"}}/>}
                                onChange={(event, newValue) => {

                                    var tempData = [...props.data.productTypes];
                                    for (var i = 0; i < tempData.length; i++) {
                                        if (tempData[i].id === newValue.id) {
                                            tempData[i].isSaved = 1;
                                        }
                                    }
                                    var newData = props.data;
                                    newData.productTypes = tempData;
                                    filterProductTypes(newData);
                                    setKeyProductTypes(prev => prev + Date.now());
                                }}
                            />
                            <Stack direction="column" spacing={1} className={classes.input_string} style={{backgroundColor: "transparent", maxWidth: 500}}>
                                {productTypesUsed.map((item, index) => (
                                    <div>
                                        <Chip
                                            label={<Typography style={{userSelect: 'text'}}>{item.label}</Typography>}
                                            deleteIcon={isReadOnly ? <></> : <Cancel/>}
                                            onDelete={() => {
                                                var tempData = [...props.data.productTypes];
                                                for (var i = 0; i < tempData.length; i++) {
                                                    if (tempData[i].id === item.id) {
                                                        tempData[i].isSaved = 0;
                                                    }
                                                }
                                                var newData = props.data;
                                                newData.productTypes = tempData;
                                                setProductTypes(newData);
                                                filterProductTypes(newData);
                                                setKeyProductTypes(prev => prev + Date.now());
                                            }}
                                            sx={{justifyContent: "flex-start"}}
                                        />
                                    </div>
                                ))}
                            </Stack>
                            <div className={classes.horizontalSpacerSmall}/>
                            <Autocomplete
                                key={keyTargetGroups}
                                disablePortal
                                id="combo-box-demo"
                                className={classes.input_string}
                                style={{marginBottom: 5}}
                                options={targetGroups}
                                readOnly={isReadOnly ? true : false}
                                renderInput={(params) => <TextField {...params} label="Target Group" style={{backgroundColor: isReadOnly ? "#00000012" : "#fff"}}/>}
                                onChange={(event, newValue) => {
                                    var tempData = [...props.data.targetGroups];
                                    for (var i = 0; i < tempData.length; i++) {
                                        if (tempData[i].id === newValue.id) {
                                            tempData[i].isSaved = 1;
                                        }
                                    }
                                    var newData = props.data;
                                    newData.targetGroups = tempData;
                                    setTargetGroups(newData);
                                    filterTargetGroups(newData);
                                    setKeyTargetGroups(prev => prev + Date.now());
                                }}
                            />
                            <Stack direction="row" spacing={1} className={classes.input_string} style={{backgroundColor: "transparent", maxWidth: 500, flexWrap: "wrap"}}>
                                {targetGroupsUsed.map((item, index) => (
                                    <div>
                                        <Chip
                                            label={<Typography style={{userSelect: 'text'}}>{item.label}</Typography>}
                                            deleteIcon={isReadOnly ? <></> : <Cancel/>}
                                            onDelete={() => {
                                                var tempData = [...props.data.targetGroups];
                                                for (var i = 0; i < tempData.length; i++) {
                                                    if (tempData[i].id === item.id) {
                                                        tempData[i].isSaved = 0;
                                                    }
                                                }
                                                var newData = props.data;
                                                newData.targetGroups = tempData;
                                                setTargetGroups(newData);
                                                filterTargetGroups(newData);
                                                setKeyTargetGroups(prev => prev + Date.now());
                                            }}
                                            sx={{justifyContent: "flex-start", marginBottom: 1}}
                                        />
                                    </div>
                                ))}
                            </Stack>
                        </div>
                        <div className={classes.column_center_half}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className={classes.input_string}
                                options={producers}
                                value={producerLabel}
                                readOnly={isReadOnly ? true : false}
                                renderInput={(params) => <TextField {...params} label="Producer" style={{backgroundColor: isReadOnly ? "#00000012" : "#fff"}}/>}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setIdProducer(newValue.id);
                                        setProducerLabel(newValue.label);
                                    } else {
                                        setIdProducer(0);
                                        setProducerLabel("");
                                    }
                                }}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className={classes.input_string}
                                options={brands}
                                value={brandLabel}
                                readOnly={isReadOnly ? true : false}
                                renderInput={(params) => <TextField {...params} label="Brand" style={{backgroundColor: isReadOnly ? "#00000012" : "#fff"}}/>}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setIdBrand(newValue.id);
                                        setBrandLabel(newValue.label);
                                    } else {
                                        setIdBrand(0);
                                        setBrandLabel("");
                                    }
                                }}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className={classes.input_string}
                                options={countryOfOrigin}
                                value={countryLabel}
                                readOnly={isReadOnly ? true : false}
                                renderInput={(params) => <TextField {...params} label="Country of Origin" style={{backgroundColor: isReadOnly ? "#00000012" : "#fff"}}/>}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setIdCountry(newValue.id);
                                        setCountryLabel(newValue.label);
                                    } else {
                                        setIdCountry(0);
                                        setCountryLabel("");
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.column_right}>
                        <Status isSaving={isSaving} isReadOnly={isReadOnly} setIsReadOnly={setIsReadOnly} data={data} section={props.section}/>
                    </div>
                </div>
                {!isReadOnly ? (
                    <div className={classes.container_outer_save_button}>
                        <Button style={{color: COLOR_BRAND, backgroundColor: "#fff", marginRight: 10}} onClick={cancelForm}>Cancel</Button>
                        <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} variant="contained" size="medium" onClick={saveForm}>SAVE</Button>
                    </div>
                ) : null}
            </div>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={1000}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );
}
