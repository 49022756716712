import React from "react";
import {makeStyles} from "@mui/styles";
import {useSelector} from 'react-redux';
import {
    Apps,
    Science,
    PhoneIphone,
    MarkEmailUnread,
    CleanHands,
    EmojiPeople,
    UploadFile
} from "@mui/icons-material";
import ListUI from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Logout} from "@mui/icons-material";
import {NavLink as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        width: 250,
        height: "100%",
        backgroundColor: "#f2f2f2",
    },
    logo: {
        height: 40,
        width: "auto",
        marginLeft: 17,
        marginTop: 10
    },
    font: {
        fontFamily: "darkmode-on, sans-serif",
        fontWeight: 400,
        color: "#000"
    },
    subItem: {
        backgroundColor: "#fff",
    },
    subItemAction: {
        backgroundColor: "#fff",
    },
    subItemLabel: {
        color: "#000000cc",
        '& span, & svg': {
            fontSize: '.75rem',
            fontFamily: "darkmode-on, sans-serif",
            fontWeight: 400
        }
    },
    subItemLabel2: {
        color: "#000000cc",
        '& span, & svg': {
            fontSize: '.65rem',
            fontFamily: "darkmode-on, sans-serif",
            fontWeight: 400
        },
        marginLeft: 20
    },
    subItemActionLabel: {
        color: "#000000cc",
        '& span, & svg': {
            fontSize: '.75rem',
            fontFamily: "darkmode-on, sans-serif",
            fontWeight: 400
        }
    },
    footerContainer: {
        position: "absolute",
        bottom: 0,
        padding: 5,
        width: "100%"
    },
    footerMetaContainer: {
        display: "flex",
        flexDirection: "row"
    },
    footerMetaLangContainer: {
        flex: 4
    },
    footerMetaLogoutContainer: {
        flex: 1
    }
}));

function ProductsSidebar() {
    const classes = useStyles();


    return (
        <>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/product_types`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Product Types"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/keywords`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Keywords"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/target_groups`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Target Groups"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/producers`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Producers"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/brands`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Brands"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/flavours`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Flavours"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/packaging_materials`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Packaging Materials"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/certificates`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Certificates"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
        </>
    );
}

function IngredientsSidebar() {
    const classes = useStyles();

    return (
        <>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/functions`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Functions"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"requests"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/sentences_h`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"H-sentences"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"requests"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/sentences_h_categories`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Categories"} className={classes.subItemLabel2} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/sentences_g`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"G-sentences"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
            <ListItem
                key={"requests"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/sentences_g_categories`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Categories"} className={classes.subItemLabel2} inset={true}/>
            </ListItem>
            <ListItem
                key={"moverview"}
                style={{paddingTop: 0, paddingBottom: 0}}
                component={(props) => (
                    <RouterLink
                        to={`/origins`}
                        activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                        {...props}
                    />
                )}
                button
                className={classes.subItem}
            >
                <ListItemText primary={"Origins"} className={classes.subItemLabel} inset={true}/>
            </ListItem>
        </>
    );
}

export function NavBar(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);

    const navItems = [
        {
            label: "Dashboard",
            icon: <Apps className={classes.font}/>,
            to: "/",
        },
        {
            label: "Products",
            icon: <CleanHands className={classes.font}/>,
            to: "/products",
            nestedBar: <ProductsSidebar/>,
        },
        {
            label: "Ingredients",
            icon: <Science className={classes.font}/>,
            to: "/ingredients",
            nestedBar: <IngredientsSidebar/>,
        },
        {
            label: "Push Notifications",
            icon: <MarkEmailUnread className={classes.font}/>,
            to: "/"
        },
        {
            label: "People",
            icon: <EmojiPeople className={classes.font}/>,
            to: "/"
        },
        {
            label: "Import",
            icon: <UploadFile className={classes.font}/>,
            to: "/"
        }

        /*
        {
          label: "Support",
          icon: <PhoneIphone className={classes.font} />,
          to: "/"
        }
        */
    ];

    return (
        <div className={classes.container}>
            <img src="images/logo.png" className={classes.logo}/>
            <ListUI>
                {navItems.map((item, index) => (
                    <>
                        <ListItem
                            key={index}
                            button
                            component={(props) => {
                                if (item.to === '/' || item.to === '') {
                                    return (
                                        <RouterLink
                                            to={'/'}
                                            {...props}
                                        />
                                    );
                                } else {
                                    return (
                                        <RouterLink
                                            to={`${item.to}`}
                                            activeStyle={{"box-shadow": "0.5rem 0 0 0 #c5b08c inset"}}
                                            {...props}
                                        />
                                    );
                                }
                            }}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText disableTypography={true} primary={item.label} className={classes.font}/>
                        </ListItem>
                        {item.nestedBar}
                    </>
                ))}
            </ListUI>
            <div className={classes.footerContainer}>
                {user && (
                    <>{user.name}</>
                )} <br/>
                <div className={classes.footerMetaContainer}>
                    <div className={classes.footerMetaLangContainer}>
                        EN
                    </div>
                    <div className={classes.footerMetaLogoutContainer}>
                        <Logout className={classes.logout} onClick={() => {
                            props.signOut();
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    );

}
