import {createStore} from 'redux';

const initialState = {
    user: {
        id: null,
        name: null,
        is_admin: 0
    },
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_USER':
            return {...state, user: action.payload};
        default:
            return state;
    }
}

const Store = createStore(reducer);

export default Store;
