import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {API_ENDPOINT, COLOR_BRAND} from "../../../consts";

const useStyles = makeStyles((theme) => ({
    container_fillIn: {
        display: "flex",
        width: "100%",
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "#f1f1f1",
        color: "#000",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        flexDirection: "row"
    },
    container_fillIn_label_container: {
        display: "flex",
        paddingLeft: 25,
        flex: 1,
        alignItems: "center"
    },
    container_fillIn_button_container: {
        flex: 6
    },
    container_fillIn_button: {
        display: "inline-block",
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: "#ffffff99",
        border: "1px solid rgba(0,0,0,.2)",
        cursor: "pointer",
        color: COLOR_BRAND,
        fontSize: 23
    }
}));


export default function (props) {
    const classes = useStyles();

    return (
        <div className={classes.container_fillIn}>
            <div className={classes.container_fillIn_label_container}>
                <b>{props.label}</b>
            </div>
            <div className={classes.container_fillIn_button_container}>
                <div className={classes.container_fillIn_button}>
                    +
                </div>
            </div>
        </div>
    );
}
