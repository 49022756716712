import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1
    },
    innerContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    box: {
        width: "100%",
        maxWidth: 1200,
        height: 400,
        backgroundColor: "#eaeaea",
        padding: 20
    }
}));

export const Dashboard = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <h1>Dashboard/Home</h1>
            <div className={classes.innerContainer}>

            </div>
        </div>
    );

}
