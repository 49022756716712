import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {DataGrid} from '@mui/x-data-grid';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import {Delete, Edit, AddPhotoAlternate, ImageSearch, Refresh} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TablePagination from '@mui/material/TablePagination';
import {API_ENDPOINT, IMAGES_ENDPOINT, COLOR_BRAND} from "../consts";
import {getDataUriFromBase64} from "../utilities/image";

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1
    },
    innerContainer: {
        display: "flex",
        width: "100%"
    },
    menuContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 60
    },
    box: {
        width: "100%",
        maxWidth: 1200,
        minHeight: 600,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 120,
        backgroundColor: "#fff",
        webkitBoxShadow: "1px 2px 3px #00000033",
        mozBoxShadow: "1px 2px 3px #00000033",
        boxShadow: "1px 2px 3px #00000033",
        borderRadius: 5
    },
    input_name: {
        width: 400
    },
    input_description: {
        width: "100%",
        top: 20
    },
    headerContainer: {
        width: 1200,
        display: "flex",
        flexDirection: "row"
    },
    headerInnerContainerLeft: {
        display: "flex",
        flex: 1,
        alignItems: "center"
    },
    headerInnerContainerRight: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end"
    },
    avatarInputFile: {
        display: "none",
        position: "absolute",
        top: 0,
        right: 0
    },
    removeImage: {
        marginBottom: 30,
        marginTop: 0,
        marginRight: 5,
        fontSize: 10,
        color: COLOR_BRAND,
        cursor: "pointer",
        textDecoration: "none"
    }
}));

export function Certificates() {
    const [rowsInit, setRowsInit] = useState([]);
    const [rows, setRows] = useState([]);
    const classes = useStyles();
    const navigate = useNavigate();
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [paginationLimit, setPaginationLimit] = useState(10);
    const [paginationPage, setPaginationPage] = useState(0);
    const [isAlertEdit, setIsAlertEdit] = useState(false);
    const [isAlertDelete, setIsAlertDelete] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedName, setSelectedName] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedImageFormFile, setSelectedImageFormFile] = useState("");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        axios.get(API_ENDPOINT + '?func=get_certificates')
            .then(res => {
                let data = res.data;
                if (res.status === 200) {
                    setRowsInit(data);
                    setRows(data);
                }
            });
    };

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    const handleChangePage = (e, newNumber) => {
        setPaginationPage(newNumber);
    };

    const handleChangeRowsPerPage = (e) => {
        setPaginationLimit(parseInt(e.target.value));
        setPaginationPage(0);
    };

    const openAlertEdit = (x) => {
        if (x > -1) {
            setSelectedName(rows[x].name);
            setSelectedDescription(rows[x].description);
            if (rows[x].image_blob.length > 0) setSelectedImage(getDataUriFromBase64(rows[x].image_blob));
        }
        setSelectedIndex(x);
        setIsAlertEdit(true);
    };

    const openAlertDelete = (x) => {
        setSelectedName(rows[x].name);
        setSelectedIndex(x);
        setIsAlertDelete(true);
    };

    const closeAlertEdit = () => {
        setIsAlertEdit(false);
        setSelectedIndex(-1);
        setSelectedName("");
        setSelectedDescription("");
        setSelectedImage("");
        setSelectedImageFormFile("");
    };

    const closeAlertDelete = () => {
        setIsAlertDelete(false);
        setSelectedIndex(-1);
        setSelectedName("");
    };

    const saveData = () => {

        var id = selectedIndex > -1 ? rows[selectedIndex].id : -1;

        let data = new FormData();
        data.append('file', selectedImageFormFile);
        data.append('id', id);
        data.append('isImage', selectedImage.length > 0 ? true : false);
        data.append('name', selectedName);
        data.append('description', selectedDescription);

        const options = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                //setImageUploadProgress(Math.floor(loaded * 100 / total));
            }
        }

        axios.post(API_ENDPOINT + "?func=update_certificate", data, options)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    getData();
                    closeAlertEdit();
                    setSnackbarMessage("Certificate saved successfully");
                    setIsSnackbar(true);
                }
            });

    }

    const uploadPhoto = ({target: {files}}) => {
        setSelectedImage(window.URL.createObjectURL(files[0]));
        setSelectedImageFormFile(files[0]);
    };

    const deleteData = () => {
        var id = rows[selectedIndex].id;
        axios.get(API_ENDPOINT + '?func=delete_certificate&id=' + id)
            .then(res => {
                if (res.status === 200) {
                    getData();
                    closeAlertDelete();
                    setSnackbarMessage("Certificate deleted successfully");
                    setIsSnackbar(true);
                }
            });
    }

    return (
        <>
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    <div className={classes.headerInnerContainerLeft}>
                        <h1>Certificates</h1>
                    </div>
                    <div className={classes.headerInnerContainerRight}>
                        <a style={{color: COLOR_BRAND}} href="javascript: void(0);" onClick={() => {
                            openAlertEdit(-1);
                        }}>New Certificate</a>
                    </div>
                </div>
                <div className={classes.innerContainer}>
                    <div className={classes.box}>
                        <TablePagination
                            component="div"
                            count={rows.length}
                            page={paginationPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={paginationLimit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{marginTop: 40}}
                        />
                        <List>
                            {rows.map((item, index) => (
                                <>
                                    {index >= (paginationLimit * paginationPage) && index < ((paginationLimit * paginationPage) + paginationLimit) ? (
                                        <ListItem>
                                            <ListItemButton onClick={() => {
                                                openAlertEdit(index);
                                            }}>
                                                <ListItemAvatar>
                                                    <Avatar sx={{width: 50, height: 50, marginRight: 2}} variant="square" src={getDataUriFromBase64(item.image_blob)}/>
                                                </ListItemAvatar>
                                                <ListItemText primary={item.name} style={{flex: 10, justifyContent: "flex-start"}}/>
                                                <ListItemIcon>
                                                    <Edit style={{color: COLOR_BRAND}}/>
                                                </ListItemIcon>
                                            </ListItemButton>
                                            <ListItemIcon>
                                                <ListItemButton onClick={() => {
                                                    openAlertDelete(index);
                                                }}>
                                                    <Delete style={{color: COLOR_BRAND}}/>
                                                </ListItemButton>
                                            </ListItemIcon>
                                        </ListItem>
                                    ) : null}
                                </>
                            ))}
                        </List>
                        <TablePagination
                            component="div"
                            count={rows.length}
                            page={paginationPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={paginationLimit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
            <Dialog
                open={isAlertEdit}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div>
                        <h3>{selectedIndex == -1 ? "New" : "Edit"} Certificate</h3>
                        {selectedImage.length > 0 ? (
                            <img
                                style={{width: "auto", height: 150, marginBottom: 4}}
                                variant="square"
                                src={selectedImage.length > 0 ? selectedImage : ""}
                            />
                        ) : (
                            <Avatar
                                style={{width: 200, height: 150, marginBottom: 4}}
                                variant="square"
                                src={""}
                            />
                        )}

                        {/*Allow only jpeg, jpg, png*/}
                        <input id="file-upload" type="file" className={classes.avatarInputFile} onChange={uploadPhoto} accept="image/jpeg, image/jpg, image/png"/>
                        {selectedImage.length > 0 ? (
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <label for="file-upload" style={{cursor: "pointer"}}>
                                    <div className={classes.removeImage}>
                                        <AddPhotoAlternate/>
                                    </div>
                                </label>
                                <a className={classes.removeImage} href={selectedImage.length > 0 ? selectedImage : ""} target="_blank"><ImageSearch/></a>
                                <div className={classes.removeImage} onClick={() => {
                                    setSelectedImage("");
                                    setSelectedImageFormFile("");
                                }}><Delete/></div>
                            </div>
                        ) : (
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <label for="file-upload" style={{cursor: "pointer"}}>
                                    <div className={classes.removeImage}><AddPhotoAlternate/></div>
                                </label>
                                {selectedIndex > -1 && rows[selectedIndex].image.length > 0 ? (
                                    <div className={classes.removeImage} onClick={() => {
                                        setSelectedImage(IMAGES_ENDPOINT + "certificates/" + rows[selectedIndex].image);
                                    }}>
                                        <Refresh/>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        <TextField
                            id="standard-basic"
                            label="Name"
                            variant="outlined"
                            className={classes.input_name}
                            onChange={value => {
                                setSelectedName(value.target.value);
                            }}
                            value={selectedName}
                        />
                        <TextField
                            id="standard-basic"
                            label="Description"
                            variant="outlined"
                            className={classes.input_description}
                            onChange={value => {
                                setSelectedDescription(value.target.value);
                            }}
                            value={selectedDescription}
                            multiline
                            rows={4}
                        />

                    </div>
                </DialogContent>
                <DialogActions style={{marginTop: 20}}>
                    <Button style={{color: COLOR_BRAND, backgroundColor: "#fff"}} onClick={closeAlertEdit}>Cancel</Button>
                    <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} onClick={saveData} autoFocus>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isAlertDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div>
                        <h3>Delete confirmation</h3>
                        <b>Certificate:</b> {selectedName}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: COLOR_BRAND, backgroundColor: "#fff"}} onClick={closeAlertDelete}>Cancel</Button>
                    <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} onClick={deleteData} autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={1000}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );

}
