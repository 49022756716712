import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {ArrowBack} from "@mui/icons-material";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useLocation, useNavigate, NavLink} from "react-router-dom";
import axios from "axios";
import {API_ENDPOINT, COLOR_BRAND} from "../consts";

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1
    },
    pageLabelContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    pageLabelArrow: {
        marginRight: 20,
        cursor: "pointer",
        color: "#000"
    },
    innerContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    box: {
        display: "flex",
        width: "100%",
        maxWidth: 800,
        padding: 20,
        borderRadius: 20,
        flexDirection: "column",
    },
    titleInput: {
        flex: 1,
        width: "55%"
    },
    bodyInput: {
        position: "relative",
        flex: 1,
        padding: 20,
        top: 20
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 40
    },
    buttonSpace: {
        width: "100%",
        height: 20
    }
}));

export function PushNotificationsSend() {
    const {state} = useLocation();
    const [select, setSelect] = useState(false);
    const [selectEvent, setSelectEvent] = useState(false);
    const [pushToken, setPushToken] = useState(-1);
    const [pushEventId, setPushEventId] = useState("");
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const classes = useStyles();
    const [isAlert, setIsAlert] = useState(false);
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    const sendNow = async () => {
        closeAlert();
        axios.post(API_ENDPOINT + "?func=send_push_notification", {
            push_token: pushToken,
            pushEventId: pushEventId,
            title: title,
            pushbody: body
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    navigate("/push_notifications");
                } else {
                    setSnackbarMessage("Push notification failed");
                    setIsSnackbar(true);
                }
            });
    }

    const handleFormSelectChange = (e) => {
        setPushEventId(e.target.value);
    };

    const getEvents = () => {
        axios.get(API_ENDPOINT + '?func=get_push_notification_events')
            .then(res => {
                if (res.status === 200) {
                    setEvents(res.data);
                }
            });
    };

    const openAlert = () => {
        if (title.length > 0 && body.length > 0) {
            if ((selectEvent && pushEventId > 0) || !selectEvent) {
                setIsAlert(true);
            }
        }
    };

    const closeAlert = () => {
        setIsAlert(false);
    };

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.pageLabelContainer}>
                    <NavLink to="/push_notifications"><ArrowBack className={classes.pageLabelArrow}/></NavLink>
                    <h1>Send a Push Notification</h1>
                </div>
                <div className={classes.innerContainer}>
                    <div className={classes.box}>
                        {!select ? (
                            <>
                                <Button variant="contained" size="medium" onClick={() => {
                                    setSelect(true);
                                }}>
                                    EVERYONE
                                </Button>
                                <div className={classes.buttonSpace}/>
                                <Button variant="contained" size="medium" onClick={() => {
                                    getEvents();
                                    setSelectEvent(true);
                                    setSelect(true);
                                }}>
                                    INDIVIDUAL
                                </Button>
                            </>
                        ) : (
                            <>
                                <div className={classes.pageLabelContainer}>
                                    <ArrowBack className={classes.pageLabelArrow} onClick={() => {
                                        setSelectEvent(false);
                                        setSelect(false);
                                    }}/>
                                    <h3>{!selectEvent ? "EVERYONE" : (
                                        <div style={{width: 400}}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">SELECT A CUSTOMER</InputLabel>
                                                <Select
                                                    name="id_type"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Category"
                                                    defaultValue={0}
                                                    value={pushEventId}
                                                    onChange={handleFormSelectChange}
                                                >
                                                    <MenuItem value='0'></MenuItem>
                                                    {events.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>{item.name} ({item.ticketBuys})</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )}</h3><br/>
                                </div>
                                <TextField
                                    id="1"
                                    label="Title"
                                    variant="standard"
                                    className={classes.titleInput}
                                    onChange={(value) => {
                                        setTitle(value.target.value);
                                    }}
                                    value={title}
                                    inputProps={{maxLength: 50}}
                                />
                                <br/>
                                <TextField
                                    id="2"
                                    label="Body"
                                    variant="standard"
                                    className={classes.bodyInput}
                                    onChange={(value) => {
                                        setBody(value.target.value);
                                    }}
                                    value={body}
                                    inputProps={{maxLength: 100}}
                                />
                                <div className={classes.buttonContainer}>
                                    <Button variant="contained" size="medium" onClick={() => {
                                        openAlert();
                                    }}>
                                        SEND
                                    </Button>
                                </div>
                            </>
                        )}

                    </div>
                </div>
            </div>
            <Dialog
                open={isAlert}
                onClose={closeAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to send this push notification?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlert}>Cancel</Button>
                    <Button onClick={sendNow} autoFocus>Send</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={2300}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );

}
