import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {ArrowBack} from "@mui/icons-material";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import {useLocation} from "react-router-dom";
import axios from "axios";
import {API_ENDPOINT, COLOR_BRAND} from "../consts";


const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1
    },
    pageLabelContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    pageLabelArrow: {
        marginRight: 20,
        cursor: "pointer",
        color: "#000"
    },
    innerContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    box: {
        display: "flex",
        width: "100%",
        maxWidth: 1200,
        padding: 20,
        border: "1px solid #ccc",
        borderRadius: 20,
        flexDirection: "row",
    },
    flex1: {
        flex: 1
    },
    profilepic: {
        width: 200,
        height: "auto"
    },
    name: {
        fontSize: 24,
        fontWeight: "bold"
    }
}));

export function CustomerProfile(props) {
    const {state} = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [user, setUser] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        getCustomerDetails();
    }, []);

    const getCustomerDetails = async () => {
        axios.get(API_ENDPOINT + '?func=get_customer_details&id_customer=' + state.id)
            .then(res => {
                if (res.status === 200) {
                    res.data.is_admin = res.data.is_admin === 1 ? true : false;
                    res.data.is_active = res.data.is_active === 1 ? true : false;
                    setUser(res.data);
                    setIsAdmin(res.data.is_admin);
                    setIsActive(res.data.is_active);
                }
            });
    }

    const setCustomerDetail = async (event) => {
        if (event.target.name == "is_admin") {
            setIsAdmin(event.target.checked);
        } else if (event.target.name == "is_active") {
            setIsActive(event.target.checked);
        }
        saveCustomerDetail(event.target.name, event.target.checked);
    };

    const saveCustomerDetail = async (field, value) => {
        axios.get(API_ENDPOINT + '?func=update_customer&id_customer=' + state.id + '&field=' + field + '&value=' + value)
            .then(res => {
                if (res.status === 200) {
                    setSnackbarMessage("Customer profile saved successfully");
                    setIsSnackbar(true);
                }
            });
    }

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.pageLabelContainer}>
                    <NavLink to="/customers"><ArrowBack className={classes.pageLabelArrow}/></NavLink>
                    <h1>Customer Profile</h1>
                </div>
                <div className={classes.innerContainer}>
                    <div className={classes.box}>
                        <div className={classes.flex1}>
                            <Avatar
                                src={user.image}
                                sx={{
                                    width: 150,
                                    height: 150
                                }}
                            />
                        </div>
                        <div className={classes.flex1}>
                            <div className={classes.name}>{user.name}</div>
                            <br/>
                            {user.email}<br/><br></br>
                            <FormControlLabel control={<Switch checked={isAdmin}/>} label="Admin" name="is_admin" onChange={setCustomerDetail}/><br/>
                            <FormControlLabel control={<Switch checked={isActive}/>} label="Active in community" name="is_active" onChange={setCustomerDetail}/><br/>
                        </div>
                        <div className={classes.flex1}>

                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={2300}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );

}
