import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {DataGrid} from '@mui/x-data-grid';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import {KeyboardArrowDown, Delete, Edit, ControlPoint, CreateNewFolder, ArrowRight, Circle} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {API_ENDPOINT, COLOR_BRAND} from "../consts";
import {InputAdornment} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1
    },
    innerContainer: {
        display: "flex",
        width: "100%"
    },
    menuContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 60
    },
    boxContainer: {
        display: "flex",
        width: 1200,
        flexDirection: "row"
    },
    box: {
        flex: 1,
        minHeight: 600,
        marginBottom: 120
    },
    datagrid: {
        marginTop: 40
    },
    noResults: {
        padding: 10
    },
    addContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 10,
        textDecoration: "underline",
        color: COLOR_BRAND,
        cursor: "pointer"
    },
    headerContainer: {
        width: 1200,
        display: "flex",
        flexDirection: "row"
    },
    headerInnerContainerLeft: {
        display: "flex",
        flex: 1,
        alignItems: "center"
    },
    headerInnerContainerRight: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end"
    },
    filterString: {
        width: 400,
    },
    iconContainer: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 100,
        elevation: 100,
        pointerEvents: "auto"
    },
    iconMetaContainer: {
        display: "flex",
        flexDirection: "row"
    },
    iconAddContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        zIndex: 100,
        elevation: 100,
        marginTop: 20,
        fontSize: 10,
        fontWeight: "bold",
        color: COLOR_BRAND,
        marginRight: "20px"
    }
}));

export function Keywords() {
    const navigate = useNavigate();
    const [keywords, setKeywords] = useState([]);
    const [idKeyword, setIdKeyword] = useState(0);
    const classes = useStyles();
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [isAlertModal, setIsAlertModal] = useState(false);
    const [selectedName, setSelectedName] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [func, setFunc] = useState(0); // 1=product type, 2=product category, 3=category item
    const [funcType, setFuncType] = useState(0); // 1=add, 2=edit, 3=delete
    const [id, setId] = useState(0);
    const [index, setIndex] = useState(-1);
    const [index2, setIndex2] = useState(-1);
    const [index3, setIndex3] = useState(-1);
    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        getKeywords();
    }, []);

    const getKeywords = async () => {
        axios.get(API_ENDPOINT + '?func=get_keywords')
            .then(res => {
                let data = res.data;
                if (res.status === 200) {
                    setKeywords(data);
                }
            });
    };


    const addItem = async () => {

        var api_endpoint = "";

        switch (func) {
            case 1:
                api_endpoint = "update_keyword"
                break;
            case 2:
                api_endpoint = "update_keyword_category"
                break;
            case 3:
                api_endpoint = "update_keyword_item"
                break;
        }

        var id_keyword = index > -1 ? keywords[index].id : 0;
        var id_keyword_category = index2 > -1 ? keywords[index].categories[index2].id : 0;

        axios.post(API_ENDPOINT + "?func=" + api_endpoint, {
            id: -1,
            id_keyword: id_keyword,
            id_keyword_category: id_keyword_category,
            name: selectedName
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                console.info(res)
                if (res.status === 200) {
                    closeAlert();
                    getKeywords();
                    setSnackbarMessage("Keyword added successfully");
                    setIsSnackbar(true);
                }
            });
    }

    const updateItem = async () => {

        var api_endpoint = "";

        switch (func) {
            case 1:
                api_endpoint = "update_product_type"
                break;
            case 2:
                api_endpoint = "update_product_category"
                break;
            case 3:
                api_endpoint = "update_category_item"
                break;
            case 4:
                api_endpoint = "update_category_item"
                break;
        }

        var id_keyword = index > -1 ? keywords[index].id : 0;
        var id_keyword_category = index2 > -1 ? keywords[index].categories[index2].id : 0;

        axios.post(API_ENDPOINT + "?func=" + api_endpoint, {
            id: id,
            id_keyword: id_keyword,
            id_keyword_category: id_keyword_category,
            name: selectedName
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    closeAlert();
                    getKeywords();
                    setSnackbarMessage("Keyword saved successfully");
                    setIsSnackbar(true);
                }
            });
    }

    const deleteItem = async () => {

        var api_endpoint = "";

        switch (func) {
            case 1:
                api_endpoint = "delete_keyword"
                break;
            case 2:
                api_endpoint = "delete_keyword_category"
                break;
            case 3:
                api_endpoint = "delete_keyword_category_item"
                break;
            case 4:
                api_endpoint = "delete_keyword_category_item"
                break;
        }

        axios.get(API_ENDPOINT + '?func=' + api_endpoint + '&id=' + id)
            .then(res => {
                console.info("res.data: " + res.data);
                if (res.status === 200) {
                    closeAlert();
                    getKeywords();
                    setSnackbarMessage("Keyword deleted successfully");
                    setIsSnackbar(true);
                }
            });
    }

    const openAlert = (event, func, funcType, id, index, index2, index3) => {
        event.stopPropagation();

        console.info("funcType: " + funcType);

        setFunc(func);
        setFuncType(funcType);
        setId(id);
        setIndex(index);
        setIndex2(index2);
        setIndex3(index3);
        if (index >= 0) {
            setSelectedIndex(index);
            if (id > -1) {
                if (func == 1) {
                    setSelectedName(keywords[index].name);
                } else if (func == 2) {
                    setSelectedName(keywords[index].categories[index2].name);
                } else if (func == 3) {
                    setSelectedName(keywords[index].items[index2].name);
                } else if (func == 4) {
                    setSelectedName(keywords[index].categories[index2].items[index3].name);
                }
            }
        }
        setIsAlertModal(true);
    };

    const closeAlert = () => {
        setIsAlertModal(false);
        setTimeout(() => {
            setFunc(0);
            setFuncType(0);
            setId(0);
            setIndex(-1);
            setIndex2(-1);
            setIndex3(-1);
            setSelectedName("");
            setIsDelete(false);
        }, 500);
    };

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    <div className={classes.headerInnerContainerLeft}>
                        <h1>Keywords</h1>
                    </div>
                    <div className={classes.headerInnerContainerRight}>
                        <a style={{color: COLOR_BRAND}} href="javascript: void(0);" onClick={(e) => {
                            openAlert(e, 1, 0);
                        }}>New Category</a>
                    </div>
                </div>
                <div className={classes.innerContainer}>
                    <div className={classes.boxContainer}>
                        <div className={classes.box}>
                            {keywords.map((item, index) => (
                                <Accordion style={{flexDirection: "row"}}>
                                    <AccordionSummary
                                        expandIcon={<KeyboardArrowDown style={{pointerEvents: "auto"}}/>}
                                        aria-controls={"panel" + index + "a-content"}
                                        id={"panel" + index + "a-header"}
                                        style={{flexDirection: "row-reverse", pointerEvents: "none"}}
                                    >
                                        <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                            <div style={{flex: 6}}>{item.name} ({item.categories.length + item.items.length})</div>
                                            <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                                                <div className={classes.iconContainer} onClick={(e) => {
                                                    openAlert(e, 1, 2, item.id, index);
                                                }}>
                                                    <Edit style={{color: COLOR_BRAND}}/>
                                                </div>
                                                <div className={classes.iconContainer} onClick={(e) => {
                                                    openAlert(e, 1, 3, item.id, index);
                                                }}>
                                                    <Delete style={{color: COLOR_BRAND}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    {item.categories.length > 0 ? (
                                        <>
                                            <AccordionDetails>
                                                {item.categories.map((item2, index2) => (
                                                    <Accordion style={{backgroundColor: "#f4f4f4", flexDirection: "row"}}>
                                                        <AccordionSummary
                                                            expandIcon={<KeyboardArrowDown style={{pointerEvents: "auto"}}/>}
                                                            aria-controls={"panel" + index + index2 + "a-content"}
                                                            id={"panel" + index + index2 + "a-header"}
                                                            style={{flexDirection: "row-reverse", pointerEvents: "none"}}
                                                        >
                                                            <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                                                <div style={{flex: 6}}>{item2.name} ({item2.items.length})</div>
                                                                <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                                                                    <div className={classes.iconContainer} onClick={(e) => {
                                                                        openAlert(e, 2, 2, item2.id, index, index2);
                                                                    }}>
                                                                        <Edit style={{color: COLOR_BRAND}}/>
                                                                    </div>
                                                                    <div className={classes.iconContainer} onClick={(e) => {
                                                                        openAlert(e, 2, 3, item2.id, index, index2);
                                                                    }}>
                                                                        <Delete style={{color: COLOR_BRAND}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                        {item2.items.length > 0 ? (
                                                            <>
                                                                <AccordionDetails style={{paddingTop: 0}}>
                                                                    <div>
                                                                        {item2.items.map((item3, index3) => (
                                                                            <div style={{paddingLeft: "2%", width: "95%", display: "flex", flexDirection: "row", backgroundColor: "#ececec", borderBottom: "1px solid #ccc", paddingTop: 15, paddingBottom: 15}}>
                                                                                <div style={{display: "flex", flex: 3, alignItems: "center"}}><ArrowRight/>&nbsp;{item3.name}</div>
                                                                                <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                                                                                    <div style={{display: "flex", flex: 1}}/>
                                                                                    <div className={classes.iconContainer} onClick={(e) => {
                                                                                        openAlert(e, 4, 2, item3.id, index, index2, index3);
                                                                                    }}>
                                                                                        <Edit style={{color: COLOR_BRAND}}/>
                                                                                    </div>
                                                                                    <div className={classes.iconContainer} onClick={(e) => {
                                                                                        openAlert(e, 4, 3, item3.id, index, index2, index3);
                                                                                    }}>
                                                                                        <Delete style={{color: COLOR_BRAND}}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        <div className={classes.iconAddContainer} style={{marginLeft: 0}} onClick={(e) => {
                                                                            openAlert(e, 3, 1, -1, index, index2);
                                                                        }}>
                                                                            <ControlPoint style={{color: COLOR_BRAND}}/> &nbsp; New Product Type
                                                                        </div>
                                                                    </div>
                                                                </AccordionDetails>
                                                            </>
                                                        ) : (
                                                            <div className={classes.iconAddContainer} style={{marginLeft: 18, marginBottom: 20}} onClick={(e) => {
                                                                openAlert(e, 3, 1, -1, index, index2);
                                                            }}>
                                                                <ControlPoint style={{color: COLOR_BRAND}}/> &nbsp; New Product Type
                                                            </div>
                                                        )}
                                                    </Accordion>
                                                ))}
                                            </AccordionDetails>
                                        </>
                                    ) : null}
                                    {item.items.length > 0 ? (
                                        <AccordionDetails style={{marginTop: -15}}>
                                            {item.items.map((item2, index2) => (
                                                <div>
                                                    <div style={{paddingLeft: "2%", width: "98%", display: "flex", flexDirection: "row", backgroundColor: "#fafafa", borderBottom: "1px solid #dddddd", paddingTop: 15, paddingBottom: 10}}>
                                                        <div style={{display: "flex", flex: 3.5, alignItems: "center"}}><ArrowRight/>&nbsp;{item2.name}</div>
                                                        <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                                                            <div style={{display: "flex", flex: 1}}/>
                                                            <div className={classes.iconContainer}>
                                                                <Edit style={{color: COLOR_BRAND}} onClick={(e) => {
                                                                    openAlert(e, 3, 2, item2.id, index, index2);
                                                                }}/>
                                                            </div>
                                                            <div className={classes.iconContainer}>
                                                                <Delete style={{color: COLOR_BRAND}} onClick={(e) => {
                                                                    openAlert(e, 3, 3, item2.id, index, index2);
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    ) : null}
                                    <div className={classes.iconMetaContainer} style={{marginLeft: 20, marginBottom: 20, marginTop: -10}}>
                                        <div className={classes.iconAddContainer} onClick={(e) => {
                                            openAlert(e, 3, 1, -1, index);
                                        }}>
                                            <ControlPoint style={{color: COLOR_BRAND}}/> &nbsp; New Product Type
                                        </div>
                                        <div className={classes.iconAddContainer} onClick={(e) => {
                                            openAlert(e, 2, 1, -1, index);
                                        }}>
                                            <CreateNewFolder style={{color: COLOR_BRAND}}/> &nbsp; New Subcategory
                                        </div>
                                    </div>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={isAlertModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div>
                        <h3>{funcType == 2 ? "Edit" : funcType == 3 ? "Delete" : "New"} {func == 2 ? "Subcategory" : func == 3 ? "Product Type" : "Category"}</h3>
                        {funcType != 3 ? (
                            <>
                                <TextField
                                    id="standard-basic"
                                    variant="outlined"
                                    className={classes.filterString}
                                    onChange={value => {
                                        setSelectedName(value.target.value);
                                    }}
                                    value={selectedName}
                                />

                            </>
                        ) : selectedName}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: COLOR_BRAND, backgroundColor: "#fff"}} onClick={closeAlert}>Cancel</Button>
                    <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} onClick={() => {
                        console.info("func: " + func);
                        console.info("funcType: " + funcType);
                        if (funcType == 2) {
                            updateItem();
                        } else if (funcType == 3) {
                            deleteItem();
                        } else {
                            addItem();
                        }
                    }} autoFocus>{funcType == 2 ? "Save" : funcType == 3 ? "Delete" : "Save"}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={2000}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );

}
