//export const API_ENDPOINT = "https://greenscan.brandgear.cz/_api/backoffice/";
export const isDev = () => {
    return process.env.NODE_ENV === "development";
}


export const API_ENDPOINT = isDev() ? "https://gapi.test/api/backoffice/" : "https://api.greenscan.cz/api/backoffice/";
export const IMAGES_ENDPOINT = "https://greenscan.brandgear.cz/_api/images/";
export const GOOGLE_BUCKET = "https://storage.googleapis.com/greenscan/";
export const COLOR_BRAND = "#00a64f";
export const COLOR_INDICATOR1 = "#dddddd";
export const COLOR_INDICATOR2 = "#f540da";
export const COLOR_INDICATOR3 = "#3b71ed";
