import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {DataGrid} from '@mui/x-data-grid';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import {Delete, Edit, SavedSearchTwoTone} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TablePagination from '@mui/material/TablePagination';
import {API_ENDPOINT, COLOR_BRAND} from "../consts";

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1
    },
    innerContainer: {
        display: "flex",
        width: "100%"
    },
    menuContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 60
    },
    box: {
        width: "100%",
        maxWidth: 1200,
        minHeight: 600,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 120,
        backgroundColor: "#fff",
        webkitBoxShadow: "1px 2px 3px #00000033",
        mozBoxShadow: "1px 2px 3px #00000033",
        boxShadow: "1px 2px 3px #00000033",
        borderRadius: 5
    },
    filterString: {
        width: 400,
    },
    headerContainer: {
        width: 1200,
        display: "flex",
        flexDirection: "row"
    },
    headerInnerContainerLeft: {
        display: "flex",
        flex: 1,
        alignItems: "center"
    },
    headerInnerContainerRight: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

export function Brands() {
    const [rowsInit, setRowsInit] = useState([]);
    const [rows, setRows] = useState([]);
    const classes = useStyles();
    const navigate = useNavigate();
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [paginationLimit, setPaginationLimit] = useState(10);
    const [paginationPage, setPaginationPage] = useState(0);
    const [isAlertEdit, setIsAlertEdit] = useState(false);
    const [isAlertDelete, setIsAlertDelete] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedName, setSelectedName] = useState("");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        axios.get(API_ENDPOINT + '?func=get_brands')
            .then(res => {
                let data = res.data;
                console.info(data);
                if (res.status === 200) {
                    setRowsInit(data);
                    setRows(data);
                }
            });
    };

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    const filterRows = async (string) => {
        var foundRows = [];
        if (string.length == 0) {
            getData();
        } else {
            for (var i = 0; i < rowsInit.length; i++) {
                if (
                    rowsInit[i].name.toLowerCase().indexOf(string.toLowerCase()) !== -1
                ) {
                    foundRows[foundRows.length] = rowsInit[i];
                }
            }
        }
        setRows(foundRows);
    };

    const handleChangePage = (e, newNumber) => {
        setPaginationPage(newNumber);
    };

    const handleChangeRowsPerPage = (e) => {
        setPaginationLimit(parseInt(e.target.value));
        setPaginationPage(0);
    };

    const openAlertEdit = (x) => {
        if (x > -1) {
            setSelectedName(rows[x].name);
        }
        setSelectedIndex(x);
        setIsAlertEdit(true);
    };

    const openAlertDelete = (x) => {
        setSelectedName(rows[x].name);
        setSelectedIndex(x);
        setIsAlertDelete(true);
    };

    const closeAlertEdit = () => {
        setIsAlertEdit(false);
        setSelectedIndex(-1);
        setSelectedName("");
    };

    const closeAlertDelete = () => {
        setIsAlertDelete(false);
        setSelectedIndex(-1);
        setSelectedName("");
    };

    const lookupName = () => {
        axios.post(API_ENDPOINT + "?func=lookup_brand", {
            name: selectedName,
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                console.info("res.data: " + res.data);
                if (res.data !== 1) {
                    saveData();
                } else {
                    setSnackbarMessage("This brand name already exists");
                    setIsSnackbar(true);
                }
            });
    }

    const saveData = () => {
        var id = selectedIndex > -1 ? rows[selectedIndex].id : -1;
        axios.post(API_ENDPOINT + "?func=update_brand", {
            id: id,
            name: selectedName,
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    getData();
                    closeAlertEdit();
                    setSnackbarMessage("Brand saved successfully");
                    setIsSnackbar(true);
                }
            });
    }

    const deleteData = () => {
        var id = rows[selectedIndex].id;
        axios.get(API_ENDPOINT + '?func=delete_brand&id=' + id)
            .then(res => {
                if (res.status === 200) {
                    getData();
                    closeAlertDelete();
                    setSnackbarMessage("Brand deleted successfully");
                    setIsSnackbar(true);
                }
            });
    }

    return (
        <>
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    <div className={classes.headerInnerContainerLeft}>
                        <h1>Brands</h1>
                    </div>
                    <div className={classes.headerInnerContainerRight}>
                        <a style={{color: COLOR_BRAND}} href="javascript: void(0);" onClick={() => {
                            openAlertEdit(-1);
                        }}>New Brand</a>
                    </div>
                </div>
                <div className={classes.innerContainer}>
                    <div className={classes.box}>
                        <TextField
                            id="standard-basic"
                            label="Search"
                            variant="outlined"
                            className={classes.filterString}
                            onChange={value => {
                                filterRows(value.target.value);
                            }}
                        />
                        <TablePagination
                            component="div"
                            count={rows.length}
                            page={paginationPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={paginationLimit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{marginTop: 40}}
                        />
                        <List>
                            {rows.map((item, index) => (
                                <>
                                    {index >= (paginationLimit * paginationPage) && index < ((paginationLimit * paginationPage) + paginationLimit) ? (
                                        <ListItem>
                                            <ListItemButton onClick={() => {
                                                openAlertEdit(index);
                                            }}>
                                                <ListItemText primary={item.name}/>
                                                <ListItemIcon>
                                                    <Edit style={{color: COLOR_BRAND}}/>
                                                </ListItemIcon>
                                            </ListItemButton>
                                            <ListItemIcon>
                                                <ListItemButton onClick={() => {
                                                    openAlertDelete(index);
                                                }}>
                                                    <Delete style={{color: COLOR_BRAND}}/>
                                                </ListItemButton>
                                            </ListItemIcon>
                                        </ListItem>
                                    ) : null}
                                </>
                            ))}
                        </List>
                        <TablePagination
                            component="div"
                            count={rows.length}
                            page={paginationPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={paginationLimit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
            <Dialog
                open={isAlertEdit}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div>
                        <h3>{selectedIndex == -1 ? "New" : "Edit"} Brand</h3>
                        <TextField
                            id="standard-basic"
                            variant="outlined"
                            className={classes.filterString}
                            onChange={value => {
                                setSelectedName(value.target.value);
                            }}
                            value={selectedName}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: COLOR_BRAND, backgroundColor: "#fff"}} onClick={closeAlertEdit}>Cancel</Button>
                    <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} onClick={lookupName} autoFocus>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isAlertDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div>
                        <h3>Delete confirmation</h3>
                        <b>Brand:</b> {selectedName}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: COLOR_BRAND, backgroundColor: "#fff"}} onClick={closeAlertDelete}>Cancel</Button>
                    <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} onClick={deleteData} autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={2300}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );

}
